import contentLoaded from './vendor/contentloaded.min.js'
import './vendor/jquery.viewportchecker.min.js'
import images from './images.js'
import phoneConcat from './phone-concatination.js'
import polyfill from './polyfills.js'
import sliders from './sliders.js'
import stickyHeader from './sticky-header.js'
import videos from './video-embeds.js'
import viewAnimation from './view-animation.js'
import getCurrentMonth from './month.js'
import kkThankYou from './thank-you.js'
import initModal from './initModal.js'
import exitPopup from './exit-popup.js'
contentLoaded()

//easing()
//validate()

window.contentLoaded(window, function (e) {
	polyfill()
	kkThankYou()
	phoneConcat()
	images()
	getCurrentMonth()
	sliders()
	stickyHeader()
	videos()
	viewAnimation()
	getCurrentMonth()
	initModal()
	exitPopup()
	//accordion()
	//timer()
	//multistep();
})
