export default function exitPopup() {
	const popupOfferBtn = document.querySelector('.popup-offer')
	if (popupOfferBtn) {
		popupOfferBtn.addEventListener('click', (e) => {
			document.querySelector('.modal-close-btn').click()
		})
	}

	// Exit Popup
	function onMouseOut(event) {
		if (
			event.clientY < 50 &&
			event.relatedTarget == null &&
			event.target.nodeName.toLowerCase() !== 'select'
		) {
			// Remove this event listener
			document.removeEventListener('mouseout', onMouseOut)

			// Show the popup
			document.querySelector('.exit-btn').click()
		}
	}

	document.addEventListener('mouseout', onMouseOut)
}
