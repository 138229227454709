import Swiper, { Navigation, Pagination, Autoplay } from 'swiper'
Swiper.use([Navigation, Pagination, Autoplay])

export default function sliders() {
	;(function ($) {
		$(window).on('load', function () {
			// get all sliders, we need to loop them due to different settings + nav
			var swipers = document.querySelectorAll('.swiper-container')
			swipers.forEach(function (el, index) {
				var closestSection = el.closest('section')
				var controls = closestSection.querySelector('.control')

				// slider settings
				var options = {
					speed: 600,
					loop: true,
					//autoplay: {
					//delay: 5000,
					//disableOnInteraction: true,
					//},
					navigation: {
						nextEl: '.swiper-button-next',
						prevEl: '.swiper-button-prev',
					},
					freeMode: true,
					centeredSlides: true,
					slidesPerView: 1,
					spaceBetween: 10,

					breakpoints: {
						640: {
							spaceBetween: 60,
							slidesPerView: 1,
						},
						992: {
							spaceBetween: 88,
						},
						1172: {
							spaceBetween: 94,
							slidesPerView: 1,
						},
						on: {
							slideChange: (swiper) => {
								let image =
									swiper.slides[
										swiper.activeIndex
									].querySelector('img[data-src]')
								if (image) {
									loadSlideImage(image);
								}
							},
						},
					},
				}

				// init slider
				new Swiper(el, options)
			})
		})
	})(jQuery)
}

function loadSlideImage(image) {
	let src = image.getAttribute('[data-src]')
	let sourceSet = image.parentNode.querySelectorAll('[data-srcset]')
	if (sourceSet.length > 0) {
		for (let i = 0; i < sourceSet.length; i++) {
			sourceSet[i].srcset = sourceSet[i].getAttribute('data-srcset')
		}
	}
	if ('img' === image.tagName.toLowerCase()) {
		if (src) {
			image.src = src
		}
	} else {
		image.style.backgroundImage = "url('" + src + "')"
	}
}
